<template>
  <div class="">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th scope="col" v-text="shop.title"></th>
        <th v-for="power in this.shop.powers"
             :key="power.id"
             v-text="$store.getters['shop/intervalToText'](power.intervalAt)"
        />
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Заказы</th>
        <th style="text-align: center; cursor: pointer"  v-for="power in this.shop.powers"
            :key="power.id"
            v-bind:style= "[(power.totalSum < power.value) ? {'background':'#e9f0c0'} : {'background':'#ea5d62','color':'white'}]"
            v-text="power.visibleText"
        />
      </tr>
      <tr>
        <th>Остаток</th>
        <th style="text-align: center; cursor: pointer"  v-for="power in this.shop.powers"
            :key="power.id"
             v-text="power.endPower"
        />
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'ChartItem',
  props: {
    shop: {
      type: Array,
      required: true,
    },
  },
  computed:{

  },
  methods: {
    // totalByInterval(interval){
    //   return this.getTotalByInterval(this.shop.id, interval);
    // },
  }
}
</script>

