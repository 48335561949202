<template>
  <div class="" v-if="this.shop">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th scope="col"></th>
        <th  v-for="power in this.shop.powers"
             :key="power.id"
             v-text="$store.getters['shop/intervalToText'](power.intervalAt)"
        />
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Заказов</th>
        <th style="text-align: center; cursor: pointer"  v-for="power in this.shop.powers"
            :key="power.id"
            v-bind:style= "[(power.totalSalesDelivery < power.powerDelivery) ? {'background':'#e9f0c0'} : {'background':'#ea5d62','color':'white'}]"
            v-text="power.visibleCouriersText"
        />
      </tr>
      <tr>
        <th>Остаток&nbsp;&nbsp;&nbsp;</th>
        <th style="text-align: center; cursor: pointer"  v-for="power in this.shop.powers"
            :key="power.id"
            v-text="power.endCouriersPower"
        />
      </tr>
      </tbody>
    </table>
  </div>
</template>


<script>


export default {
  name: 'ChartCouriersItem',
  data() {
    return {

    }
  },
  props: {
    shop: {
      type: Array,
      required: true,
    },
  },
  methods: {
  }
}
</script>
