import axios from "axios";
import moment from "moment";
import config from "@/config";

export const shopModule = {
    state: () => ({
        isLoading: false,
        shops: Object,
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        dateTo:moment(new Date()).format('YYYY-MM-DD'),
        error: null,
        parameters: Object
    }),
    getters: {
        intervalToText:(state)=> (interval)=>{
            if(!interval.length){
                return '';
            }
            state;
            const myDate = new Date(interval.split('+')[0]);
            const time = new Date(myDate).toLocaleTimeString('en',
                { timeStyle: 'short', hour12: false });
            const time2 = moment(myDate).add(29, 'm').toDate().toLocaleTimeString('en',
                { timeStyle: 'short', hour12: false });
            return time + '-' + time2;
        },
        getTotalSales: (state) => (id) => {
            if (!state.shops) {
                return 0;
            }
            return ((state.shops).find(x => x.id === id)).sales.length;
        },
        getTotalByStatus: (state) => (id, status) => {
            if (!state.shops) {
                return 0;
            }
            return (((state.shops).find(x => x.id === id)).sales).filter(function (item) {
                return item.status === status
            }).length;
        },
        getSumByStatus: (state) => (id, status) => {
            if (!state.shops) {
                return 0;
            }
            let sum = 0;
            (((state.shops).find(x => x.id === id)).sales).filter(function (item) {
                return ( status.includes(item.status) || status === 'ALL')
            }).forEach(function (value){
                sum += value.total;
            });
            return sum;
        },
        powerToTime:(state, getters) => (power, forCourier, currentPower, nowDate) => {
            state;
            let powerValue = power.value;

            let time = 0;
            if (power.endPower <= 0 && power.id !== currentPower.id) {
                return 30;
            } else  if (power.endPower <= 0 && power.id === currentPower.id) {
                let date = new Date(Date.parse((power.intervalAt).replace('+00:00', '')));
                return  30 - Math.abs( nowDate.getMinutes() - date.getMinutes());
            } else if (power.endPower > 0 && !forCourier) {
                return (power.totalSum / powerValue) * 30;
            } else if (power.endPower > 0 && forCourier) {
                return ( power.totalSales /  getters.couriersToPowerSales(power.couriers)) * 30;
            }
            return time;
        },
        totalSalesByPower:(state) => (power, onlyDelivery = false) => {
            let totalSale = 0;
            const powerDate = new Date(Date.parse((power.intervalAt).replace('+00:00', '')));
            state.shops.forEach(function (shop){
                if(onlyDelivery){
                    totalSale += ((shop.sales).filter(function (sale) {
                        const saleDate = new Date(Date.parse((sale.createAt).replace('+00:00', '')));
                        const endInterval = moment(powerDate).add(29, 'm').toDate();
                        return (saleDate.getTime() >= powerDate.getTime() && saleDate.getTime() <= endInterval.getTime() && sale.type === 'DeliveryByCourier' );
                    })).length;
                }else {
                    totalSale += ((shop.sales).filter(function (sale) {
                        const saleDate = new Date(Date.parse((sale.createAt).replace('+00:00', '')));
                        const endInterval = moment(powerDate).add(29, 'm').toDate();
                        return (saleDate.getTime() >= powerDate.getTime() && saleDate.getTime() <= endInterval.getTime());
                    })).length;
                }
            });
            return totalSale;
        },
        couriersToPowerSales:(state) => (countCouriers) => {
            state;
            const nowDate = new Date();

            let peakTimeFrom = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate() + ' '+ state.parameters.peakTimeFrom.value;
            let peakTimeTo = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate() +' '+ state.parameters.peakTimeTo.value;
            peakTimeFrom = new Date(Date.parse(peakTimeFrom));
            peakTimeTo = new Date(Date.parse(peakTimeTo));
            let power = parseFloat(state.parameters.normalMode.value);
            if(state.parameters.mode.value === '1.5'){
                 power = parseFloat(state.parameters.problemMode.value);
            }

            if(((nowDate.getDay() !== 6) || (nowDate.getDay()  !== 0)) && (nowDate.getTime() >= peakTimeFrom.getTime() &&  nowDate.getTime() <= peakTimeTo.getTime()  )){  // МЕЖДУ ДАТАМИ
                  power = power * (1-(parseInt(state.parameters.powerFactorWeekdays.value)/100));
            }
           return  Math.round((countCouriers * power) * 100)/100;
        },
        getEstimatedTime: (state, getters) => (shopId) => {
            state;getters;shopId;
            return ;

            // let estimatedTime = 0;
            // let estimatedCorersTime = 0;
            // state;
            // shopId;
            // const nowDate = new Date();
            // var currentPower = (((state.shops).find(x => x.id === shopId))?.powers).find(function (power) {
            //     const intervalDate = new Date(power.intervalAt.split('+')[0]);
            //     const intervalDateLast =  moment(intervalDate).add(29, 'm').toDate();
            //     return (nowDate.getTime() >= intervalDate.getTime() && nowDate.getTime() <= intervalDateLast.getTime()  );
            // });
            //
            // if(currentPower !==undefined ){
            //     var lastPower = (((state.shops).find(x => x.id === shopId))?.powers).find(function (power) {
            //         const currentPowerDate = new Date(currentPower.intervalAt.split('+')[0]);
            //         const intervalDate = new Date(power.intervalAt.split('+')[0]);
            //         return (currentPowerDate.getTime() <= intervalDate.getTime()  && power.endPower > 0);
            //     });
            //     if(lastPower !==undefined){
            //         var powers = (((state.shops).find(x => x.id === shopId))?.powers).filter(function (power) {
            //             return (power.id >= currentPower.id && power.id <= lastPower.id);
            //         });
            //         powers.forEach(function (value){
            //             estimatedTime += getters.powerToTime(value, false, currentPower, nowDate);
            //             estimatedCorersTime += getters.powerToTime(value, true, currentPower, nowDate);
            //         });
            //     }
            //     estimatedTime = estimatedTime.toFixed();
            //     estimatedCorersTime = estimatedCorersTime.toFixed();
            //     if(estimatedCorersTime > estimatedTime){
            //         estimatedTime =  estimatedCorersTime;
            //     }
            // }
            // if(estimatedTime < 60){
            //     estimatedTime = 60;
            // }
            //
            // if( currentPower !== undefined && parseInt(currentPower.max) < parseInt(estimatedTime)){
            //     currentPower.max = parseInt(estimatedTime);
            //
            //     let axiosConfig = {
            //         headers: {
            //             'accept': 'application/json',
            //             'Content-Type': 'application/merge-patch+json',
            //         }
            //     };
            //     axios.patch(config.hostname + '/api/powers/' + currentPower.id, {max:parseInt(currentPower.max)}, axiosConfig)
            // }
            // return  Math.round(estimatedTime);
        },
    },
    mutations: {
        setShops(state, data) {
            state.shops = data;
        },
        setDateFrom(state, data) {
            state.dateFrom = data;
        },
        setDateTo(state, data) {
            state.dateTo = data;
        }
    },
    actions: {


        calculatePowers({state}) {
            state.shops.forEach(function (shop) {
                shop.powers.forEach(function (power) {

                    power.visibleCouriersText = power.totalSalesDelivery;
                    if (power.transferSales) {
                        power.visibleCouriersText += '+' + power.transferSales;
                    }
                    power.visibleText = power.sum;
                    if (power.transfer) {
                        power.visibleText += '+' + power.transfer;
                    }
                })
            });
        },

        get({state, commit, dispatch}) {
            state.isLoading = true;
            let axiosConfig = {
                headers: {
                    'accept': 'application/json',
                },
                'params': {
                    'dateTo': state.dateFrom + ' 23:59:59',
                    'dateFrom': state.dateFrom + ' 00:00:00',
                }
            };
            let url = config.hostname + '/api/shops';

            axios.get(url, axiosConfig).then((response) => {
                commit('setShops', response.data);
                dispatch('calculatePowers');
            }).catch((err) => {
                state.error = err.response?.data?.message;
                if (!state.error) {
                    state.error = err.message;
                }
            }).finally(() => {
                state.isLoading = false;
            })
        }
    },

    namespaced: true
}
