<template>
  <nav class="navbar bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        График заказов
      </a>
      <span style="font-size: 12px" v-text="this.timestamp"></span>
      <button  @click="openDialog()" class="btn btn-outline-success" type="submit">Параметры</button>
    </div>
  </nav>
  <TheDialog  v-model:show="dialogVisible">
    <SettingFormBlock/>
  </TheDialog>
</template>
<script>

import SettingFormBlock from "@/components/ui/setting/form";
import TheDialog from "@/components/ui/TheDialog";
import axios from "axios";
import config from "@/config";
export default {
  name: 'SettingBlock',
  components: {TheDialog, SettingFormBlock},
  data() {
    return {
      dialogVisible: false,
      timestamp:''
    }
  },
  created() {
    this.loadParam();

    setInterval(() => {
      this.getNow();
    }, 1000)
  },
  methods: {

    getNow: function() {
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + ' ' + time;
      this.timestamp = dateTime;
    },
    loadParam(){
      let axiosConfig = {
        headers: {
          'accept': 'application/json',
        }
      };
      axios.get( config.hostname + '/api/parameters', axiosConfig).then((response) => {
        response.data.forEach( value => {
          this.$store.state.shop.parameters[value.name] = value;
        });
      })
    },
    openDialog() {
      this.dialogVisible = true;
    }
  }
}
</script>
