<template>
  <div class="container-fluid mt-4 d-flex">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            Блок ввода информации
          </div>
        </div>
        <div class="col-12">
          <PowerItem
              v-for="shop in $store.state.shop.shops"
              :shop="shop"
              :key="shop.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import PowerItem from "@/components/ui/power/item";

export default {
  name: 'PowerBlock',
  components: {PowerItem},
  data() {
    return {}
  },
  created() {

  },

}
</script>
