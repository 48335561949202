<template>

    <SettingBlock/>
    <StatisticBlock/>
    <EstimatedTime/>
    <PowerDelivery/>
    <PowerBlock/>
    <PowerMax/>
    <ChartBlock/>
    <ChartCouriers/>

</template>

<script>
import StatisticBlock from "@/components/StatisticBlock";
import PowerBlock from "@/components/PowerBlock";
import ChartBlock from "@/components/ChartBlock";
import EstimatedTime from "@/components/EstimatedTime";
import SettingBlock from "@/components/SettingBlock";
import PowerDelivery from "@/components/PowerDelivery";
import ChartCouriers from "@/components/ChartCouriers";
import PowerMax from "@/components/PowerMax";

export default {
  name: 'App',
  components: {
    PowerMax,
    ChartCouriers,
    PowerDelivery,
    SettingBlock,
    EstimatedTime,
    ChartBlock,
    PowerBlock,
    StatisticBlock,
  },
  created() {
    document.title = 'Статистика';
    this.do_check();
  },
  methods: {
    do_check() {
      if(!localStorage.getItem('isAuth')){
        var return_value = prompt("Password:");
        if (return_value === "door1552233"){
          localStorage.setItem('isAuth', true);
          return true;
        }
        this.do_check()
      }

    }
  }
}
</script>

<style>
* {
  white-space: nowrap;
}
.table-responsive{
  overflow: unset;
}
.table{
  table-layout: fixed;
}

.table th,
.table td {
  width: 110px;
  overflow: hidden;
}

</style>
