<template>
  <div class="container-fluid mt-4 d-flex" v-if="(this.shops).length>0" >

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
             Максимальное расчетное время доставки
          </div>
        </div>
        <div class="col-12">
          <PowerMaxItem
              v-for="shop in $store.state.shop.shops"
              :shop="shop"
              :key="shop.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import PowerMaxItem from "@/components/ui/powerMax/item";
export default {
  name: 'PowerMax',
  components: {PowerMaxItem},
  data() {
    return {}
  },
  computed: {
    shops: {
      get() {
        return  this.$store.state.shop.shops;
      }
    }
  },
  methods: {
  }

}
</script>
