<template>
  <div class="">

    <table class="table table-bordered">
    <thead>
    <tr>
      <th scope="col" v-text="shop.title"></th>

      <th  v-for="power in this.shop.powers"
           :key="power.id"
           v-text="$store.getters['shop/intervalToText'](power.intervalAt)"
      />
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>Мощность</th>
      <th style="text-align: center; cursor: pointer"  v-for="power in this.shop.powers"
           :key="power.id"
           v-text="power.value"
          @click="openDialogPower(power)"
      />
    </tr>

    <tr>
      <th>Курьеров</th>
      <th style="text-align: center; cursor: pointer"  v-for="power in this.shop.powers"
          :key="power.id"
          v-text="power.couriers"
          @click="openDialogPower(power)"
      />
    </tr>
    </tbody>
    <TheDialog  v-model:show="dialogVisible">
      <PowerForm
          :power="this.power"
          :shopId="this.shopId"
      />
    </TheDialog>
  </table>
  </div>
</template>

<script>

import TheDialog from "@/components/ui/TheDialog";
import PowerForm from "@/components/ui/power/form";
import moment from "moment";
export default {
  name: 'PowerItem',
  components: {PowerForm, TheDialog},
  data() {
    return {
      dialogVisible: false,
      power: Object,
      shopId: this.shop.id,
    }
  },
  props: {
    shop: {
      type: Array,
      required: true,
    },
  },
  methods: {
    openDialogPower(power) {
      const nowDate = new Date();
      const intervalDate = new Date(power.intervalAt.split('+')[0]);
      const intervalDateLast =  moment(intervalDate).add(29, 'm').toDate();
      if(intervalDateLast.getTime()>nowDate.getTime()){
        this.power = power;
        this.dialogVisible = true;
      }
    }
  }
}
</script>
