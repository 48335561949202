<template>
  <form v-on:submit.prevent="savePower">


    <h5>Промежуток: <b>{{ $store.getters['shop/intervalToText'](power.intervalAt) }} </b></h5>
    <div class="input-group mb-3">
      <span class="input-group-text">Мощность</span>
      <input v-model="this.value" type="number" class="form-control" placeholder="Число">
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">Курьеры</span>
      <input v-model="this.couriers" type="number" class="form-control" placeholder="Число">
    </div>
    <div class="form-check  mb-3">
      <input class="form-check-input" type="checkbox"  v-model="this.toEmpty" id="toEmpty">
      <label class="form-check-label" for="toEmpty">
        Применить настройки по интервлу?
      </label>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">от</span>
      <input @change="onChangeTimeFrom()" v-model="timeFrom" type="time" class="form-control" placeholder="Username" aria-label="Username">
      <span class="input-group-text">до</span>
      <input  @change="onChangeTimeTo()" v-model="timeTo" type="time" class="form-control" placeholder="Server" aria-label="Server">
    </div>
    <div class="col-auto">
      <button type="submit" class="btn btn-primary mb-3">Сохранить</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import config from "@/config";
import {mapActions} from 'vuex'
import moment from "moment";
export default {
 'name': 'PowerForm',
  data() {
    return {
      value: this.power.value,
      couriers: this.power.couriers,
      toEmpty: false,
      timeFrom: moment(new Date(this.power.intervalAt.split('+')[0])).format('H:mm'),
      timeTo:  '23:00',
    }
  },
  props: {
    power: {
      type: Object,
      required: true
    },
    shopId:{
      type: Number,
      required: true
    },
  },
  methods:{

    onChangeTimeFrom(){
      const  startTime =   new Date(Date.parse(  moment(new Date()).format('YYYY-MM-DD') + ' '+ this.timeFrom));
      const intervalDate = new Date(this.power.intervalAt.split('+')[0]);
      if(startTime.getTime()<intervalDate.getTime()){
        this.timeFrom =  new Date(intervalDate).toLocaleTimeString('en',
            { timeStyle: 'short', hour12: false });
      }
    },
    onChangeTimeTo(){
      const  startTime =   new Date(Date.parse(  moment(new Date()).format('YYYY-MM-DD') + ' '+ this.timeTo));
      const intervalDate = new Date(this.power.intervalAt.split('+')[0]);
      const intervalDateLast =  moment(intervalDate).add(29, 'm').toDate();
      if(startTime.getTime()<intervalDateLast.getTime()){
        this.timeTo =  new Date(intervalDateLast).toLocaleTimeString('en',
            { timeStyle: 'short', hour12: false });
      }
    },
    savePower(){
      var request = {};
      if(this.toEmpty===false){
        request = {
          value: this.value,
          couriers: this.couriers
        };
        this.patch(this.power.id, request);
      }else {
        let timeFrom = this.timeFrom;
        let timeTo = this.timeTo;
         let powers = (((this.$store.state.shop.shops).find(x => x.id === this.shopId))?.powers).filter(function (power) {
            const intervalDate = new Date(power.intervalAt.split('+')[0]);
           const  startTime =   new Date(Date.parse(  moment(new Date()).format('YYYY-MM-DD') + ' '+ timeFrom));
           const  endTime =    new Date(Date.parse(  moment(new Date()).format('YYYY-MM-DD') + ' '+ timeTo));
           return (intervalDate.getTime() >= startTime.getTime() && intervalDate.getTime() <= endTime.getTime() );
        });
        powers.forEach(power=> {
            request = {
              value: this.value,
              couriers: this.couriers
            };
            this.patch(power.id, request);
          });
      }

    },
    patch(id, request){
      let axiosConfig = {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/merge-patch+json',
        }
      };
       axios.patch(config.hostname + '/api/powers/' + id, request, axiosConfig).then(() => {
         this.updateShops()
       });

    },
    ...mapActions({
      'updateShops':'shop/get'
    }),
  }
}
</script>
