<template>
  <div class="col-6">
    <div class="card">
      <div class="card-header" v-text="shop.title"/>
      <div class="card-body p-2">
        Расчетное время доставки: <b v-text="this.estimatedTime"/>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: 'EstimatedTimeItem',
  props: {
    shop: {
      type: Array,
      required: true,
    },
  },
  computed: {
    estimatedTime: {
      get() {
        const nowDate = new Date();
        var currentPower = ((this.shop)?.powers).find(function (power) {
          const intervalDate = new Date(power.intervalAt.split('+')[0]);
          const intervalDateLast =  moment(intervalDate).add(29, 'm').toDate();
          return (nowDate.getTime() >= intervalDate.getTime() && nowDate.getTime() <= intervalDateLast.getTime()  );
        });

        if(currentPower!==undefined){
          return currentPower.estimatedTime;
        }
        return 'Не известно';

      }
    },
  }
}
</script>
