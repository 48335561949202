<template>
  <div class="">

    <table class="table table-bordered">
      <thead>
      <tr>
        <th v-text="this.shop.title"></th>
        <th  v-for="power in this.shop.powers"
             :key="power.id"
             v-text="$store.getters['shop/intervalToText'](power.intervalAt)"
        />
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Время</th>
        <th style="text-align: center; cursor: pointer"   v-for="power in this.shop.powers"
            :key="power.id"
            v-text="power.max"
        />
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'PowerMaxItem',
  data() {
    return {
      dialogVisible: false,
      power: Object,
      shopId: this.shop.id,
    }
  },
  props: {
    shop: {
      type: Array,
      required: true,
    },
  }
}
</script>
