<template>
  <div class="container-fluid mt-4  d-flex">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            График
          </div>
        </div>
      </div>
      <div class="col-12">
        <ChartItem
            v-for="shop in $store.state.shop.shops"
            :shop="shop"
            :key="shop.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChartItem from "@/components/ui/chart/item";
export default {
  name: 'ChartBlock',
  components: {ChartItem},
  data() {
    return {}
  }
}
</script>

<style>

</style>
