<template>
  <div class="container-fluid mt-4 d-flex" v-if="(this.shops).length>0" >

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            Расчетная мощность доставки общая
          </div>
        </div>
        <div class="col-12">
          <div class="">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Интервал&nbsp;&nbsp;&nbsp;</th>
                <th  v-for="power in this.shops[0]?.powers"
                     :key="power.id"
                     v-text="$store.getters['shop/intervalToText'](power.intervalAt)"
                />
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Мощность</th>
                <th style="text-align: center; cursor: pointer"  v-for="power in this.shops[0]?.powers"
                    :key="power.id"
                    v-text="power.powerDelivery"
                />
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'PowerDelivery',
  data() {
    return {}
  },
  computed: {
    shops: {
      get() {
        return  this.$store.state.shop.shops;
      }
    }
  },
  methods: {
  }

}
</script>
