<template>
  <div class="container-fluid">
    <div class="row mt-5">
      <div class="col-md-6 col-12">
        <div class="input-group mb-3">
          <span class="input-group-text">Статистика</span>
          <input @change="this.loadShops()" v-model="dateFrom" type="date" class="form-control">
        </div>
      </div>
      <div class="col-md-6 col-12">
        <button @click="this.loadShops()" class="btn btn-primary mr-2" type="button" >
          Обновить
          <span   v-if="$store.state.shop.isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        </button>


      </div>
    </div>

    <div class="row">
      <StatItem
          v-for="shop in $store.state.shop.shops"
          :shop="shop"
          :key="shop.id"
      />
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import StatItem from "@/components/ui/stat/item";

export default {
  name: 'StatisticBlock',
  components: {StatItem},
  data() {
    return {
    }
  },
  created() {
    this.loadShops();
    setInterval(() => {
      this.loadShops();
    }, 30000)
  },
  computed: {
    dateFrom: {
      get() {
        return this.$store.state.shop.dateFrom
      },
      set(value) {
        this.$store.commit('shop/setDateFrom', value)
      }
    },
    dateTo: {
      get() {
        return this.$store.state.shop.dateTo
      },
      set(value) {
        this.$store.commit('shop/setDateTo', value)
      }
    }
  },
  methods: {
    ...mapActions({
      'loadShops': 'shop/get'
    }),
  }
}
</script>

<style>
*{
  white-space: unset;
}
</style>
