import { createStore } from 'vuex'
import {shopModule} from "@/store/module/shop";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    shop: shopModule
  }
})
