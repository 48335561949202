<template>
  <div class="dialog" v-if="show" @click.stop="hideDialog">
    <div @click.stop class="dialog__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import toggleMixin from "@/mixins/toggleMixin";

export default {
  name: 'TheDialog',
  mixins: [toggleMixin]
}
</script>

<style scoped>
.dialog {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  overflow: auto;
  z-index: 999;
}

.dialog__content {
  z-index: 9999;
  margin: auto;
  background: white;
  border-radius: 12px;
  min-height: 50px;
  max-width: 500px;
  min-width: 500px;
  padding: 20px;
  overflow: auto;
}

@media (max-width: 750px) {
  .dialog__content {
    max-width: 350px;
    min-width: 350px;
  }
}
</style>
