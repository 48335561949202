<template>
  <div class="col-6">
    <div class="card">
      <div class="card-header" v-text="shop.title"/>
      <div class="card-body p-0">
        <ul class="list-group" style="border-radius: unset;">
          <li class="list-group-item">За весь день:
            <b v-text="$store.getters['shop/getSumByStatus'](this.shop.id, 'ALL') +'₽' "></b>
          </li>
          <li class="list-group-item">Приготовленные:
            <b v-text="this.cooked +'₽'"></b>
          </li>
          <li class="list-group-item">Осталось приготовить:
            <b v-text="this.remainsCook +'₽'"></b>
          </li>
          <li class="list-group-item">Закрытые: <b v-text="this.Closed +'₽'"></b></li>
          <li class="list-group-item">Осталось исполнить: <b v-text="this.leftDeliver +'₽' "></b></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'StatItem',
  props: {
    shop: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      'getTotalSales': 'shop/getTotalSales',
      'getTotalByStatus': 'shop/getTotalByStatus',
      'getSumByStatus': 'shop/getSumByStatus',
    }),
    totalSales: {
      get() {
        return this.getTotalSales(this.shop.id)
      }
    },
    Closed: {
      get() {
        return this.$store.getters['shop/getSumByStatus'](this.shop.id, ['Closed'])
      }
    },
    leftDeliver: {
      get() {
        return this.$store.getters['shop/getSumByStatus'](this.shop.id, 'ALL')  -  this.Closed;
      }
    },

    tillDelivery: {
      get() {
        return this.$store.getters['shop/getSumByStatus'](this.shop.id, 'ALL')  -  this.getTotalByStatus(this.shop.id, "Closed")
      }
    },
    cooked: {
      get() {
        return this.$store.getters['shop/getSumByStatus'](this.shop.id, ['CookingCompleted','Delivered','Closed','Waiting','OnWay']);
      }
    },
    remainsCook: {
      get() {
        return  this.$store.getters['shop/getSumByStatus'](this.shop.id, 'ALL') - this.cooked
      }
    },
    CookingCompleted: {
      get() {
        return this.getTotalByStatus(this.shop.id, "CookingCompleted")
      }
    },
  },
}
</script>
