<template>
  <div class="container-fluid" v-if="(this.$store.state.shop.shops).length>0">
    <div class="row mt-4 mb-4">
      <EstimatedTimeItem
          v-for="shop in $store.state.shop.shops"
          :shop="shop"
          :key="shop.id"
      />
    </div>
  </div>
</template>
<script>

import EstimatedTimeItem from "@/components/ui/estimatedtime/item";
export default {
  name: 'EstimatedTime',
  components: {EstimatedTimeItem}
}
</script>
