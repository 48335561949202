<template>
  <h5>Параметры</h5>
  <div class="mt-3">
    <div class="input-group mb-3">
      <span class="input-group-text">Режим</span>
      <select @change="updateParam('mode')" v-model="$store.state.shop.parameters.mode.value" class="form-select">
        <option value="2">Нормальный</option>
        <option value="1.5">Проблемный</option>
      </select>
    </div>

    <div class="input-group  mb-3">
      <span class="input-group-text">Нормальный режим</span>
      <input  @change="updateParam('normalMode')" v-model="$store.state.shop.parameters.normalMode.value" type="number" class="form-control" placeholder="Число">
    </div>

    <div class="input-group  mb-3">
      <span class="input-group-text">Проблемный режим</span>
      <input  @change="updateParam('problemMode')" v-model="$store.state.shop.parameters.problemMode.value" type="number" class="form-control" placeholder="Число">
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">Час пик</span>
      <input @change="updateParam('peakTimeFrom')" v-model="$store.state.shop.parameters.peakTimeFrom.value" type="time" class="form-control" placeholder="Username" aria-label="Username">
      <span class="input-group-text">до</span>
      <input @change="updateParam('peakTimeTo')" v-model="$store.state.shop.parameters.peakTimeTo.value" type="time" class="form-control" placeholder="Server" aria-label="Server">
    </div>

    <div class="input-group  mb-3">
      <span class="input-group-text">Кфц. в будни</span>
      <input max="100" @change="updateParam('powerFactorWeekdays')" v-model="$store.state.shop.parameters.powerFactorWeekdays.value" type="number" class="form-control" placeholder="Число">
    </div>
  </div>
  <hr/>
  <h5>Отчет</h5>
  <div class="mt-3">
    <div class="input-group">
      <button class="btn btn-success" @click="exportToJPG">Скачать файл</button>
      <button class="btn btn-warning" @click="sendMails">Отправить на почту</button>
    </div>

  </div>
</template>
<script>

import axios from "axios";
import config from "@/config";
import html2canvas from "html2canvas";

export default {
  name: 'SettingFormBlock',
  data() {
    return {

    }
  },
  methods:{
    sendMails(){
      let iframe = document.createElement("iframe");
      iframe.style.width = "3024px";
      iframe.style.height = "100%";
      document.body.appendChild(iframe);
      iframe.srcdoc = '<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktQOp6b7In1Zl3/Jr59b6EGGoI1aFkw7cmDA6j6gD" crossorigin="anonymous">'+ document.querySelector('#app').outerHTML;
      iframe.addEventListener("load", () => {
        html2canvas(iframe.contentWindow.document.body).then(function(canvas) {

          canvas.toBlob( function(blob) {
            const data = new FormData();
            data.append('file', blob,  "image.jpg");

            let axiosConfig = {
              headers: {
                'Content-Type': 'multipart/form-data',
              }
            };
            axios.post(config.hostname + '/commands/alert', data, axiosConfig);
            document.body.removeChild(iframe);
          }, 'image/jpg');

        });
      });
    },
    exportToJPG() {
      let iframe = document.createElement("iframe");
      iframe.style.width = "3024px";
      iframe.style.height = "100%";
      document.body.appendChild(iframe);

      iframe.srcdoc = '<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktQOp6b7In1Zl3/Jr59b6EGGoI1aFkw7cmDA6j6gD" crossorigin="anonymous">'+ document.querySelector('#app').outerHTML;

      iframe.addEventListener("load", () => {
        html2canvas(iframe.contentWindow.document.body).then(function(canvas) {
          let filename = "image.jpg";
          let link = document.createElement("a");
          link.download = filename.toLowerCase();
          canvas.toBlob( function(blob) {
            link.href = URL.createObjectURL(blob);
            link.click();
            document.body.removeChild(iframe);
          }, 'image/jpg');

        });
      });
    },
    updateParam(name){
      let axiosConfig = {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/merge-patch+json',
        }
      };
      axios.patch(config.hostname + '/api/parameters/' + this.$store.state.shop.parameters[name].id, {
        value: (this.$store.state.shop.parameters[name].value).toString()
      }, axiosConfig);
    }
  }
}
</script>
